export default ({ fns, D }) =>
  async ({
    prompt,
    nResults,
    collection,
    context,
    conversationname,
    where,
  }) => {
    const response = await fetch(
      `${
        process.env.REACT_APP_ENVIRONMENT === "production"
          ? "https://highmountainlabs.io"
          : "http://localhost:7001"
      }/api/ollama_ask?apikey=runkey`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt,
          collection,
          nResults,
          where,
          context,
          conversationname,
        }),
      }
    ).then((res) => res.json());
    return response;
  };
