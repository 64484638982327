import React, { FC, useEffect } from "react";
import Styles from "./styles";
import { Button } from "../components";

const InputPrompt: FC<{
  fns: any;
  text: Array<{ source: string; text: string }>;
  onSubmit: Function;
  askollama: Function;
}> = ({ fns, text, onSubmit, askollama }) => {
  const transformString = (text) => {
    const regex = /<pause>(\d+)<!pause>/g;
    const pause = {};
    const matches = Array.from(text.matchAll(regex));
    matches.map((el: any, i: Number) => {
      pause[el.index] = {
        duration: Number.parseInt(el[1]) * 1000,
      };
    });
    return {
      pause,
      text: text.replace(regex, ""),
    };
  };
  const ref = React.useRef<HTMLDivElement>(null);
  const ref2 = React.useRef<HTMLTextAreaElement>(null);
  const [locked, setLocked] = React.useState(true);
  const [input, setInput] = React.useState("");
  const [lastDisplay, setLastDisplay] = React.useState("");
  useEffect(() => {
    setLocked(true);
    const recur = async (fullText: { pause: any; text: string }, text = "") => {
      const el = ref.current;
      if (el) el.scrollTop = el.scrollHeight;
      setLastDisplay(text);
      if (text.length >= fullText.text.length) {
        setLocked(false);
        ref2.current?.focus();
        return;
      }
      await new Promise((r) => {
        const pause = fullText?.pause[text.length];
        const wait = pause
          ? pause.duration
          : fullText.text.charAt(text.length - 1) !== " "
          ? 15
          : 0;
        setTimeout(r, wait);
      });
      recur(fullText, `${text}${fullText.text.charAt(text.length)}`);
    };
    if (text[text.length - 1]?.text) {
      const s = transformString(text[text.length - 1].text);
      recur(s, s.text.charAt(0));
    }
  }, [text]);
  return (
    <div
      className={`arclight-w-full arclight-h-full lg:arclight-h-96 arclight-bg-background-secondary arclight-rounded-md arclight-bg-opacity-80 arclight-text-left arclight-text-sm arclight-p-5 arclight-overflow-auto arclight-overflow-x-hidden arclight-pointer-events-auto`}
      ref={ref}
      onClick={() => {
        ref2.current?.focus();
      }}
    >
      <div className={`arclight-flex-col arclight-space-y-4`}>
        {text
          .filter((el, i) => i < text.length - 1)
          .map((T: { source: string; text: string }) => (
            <div
              className={
                T.source === "agent"
                  ? `arclight-text-sky-400`
                  : `arclight-text-right`
              }
            >{`${T.source === "user" ? "" : ""}${
              transformString(T.text).text
            }`}</div>
          ))}
        <div
          className={`arclight-text-sky-400 ${
            !lastDisplay.length ? "arclight-hidden" : ""
          }`}
        >
          {lastDisplay}
        </div>
        <textarea
          ref={ref2}
          className={`arclight-w-full arclight-bg-transparent arclight-resize-none arclight-outline-none arclight-text-sm arclight-pointer-events-${
            locked ? "none" : "auto"
          } arclight-opacity-${locked ? "0" : "1"} arclight-ml-1`}
          value={`> ${input}`}
          onChange={(e: any) => {
            const v = e.target.value.split("> ")[1];
            setInput(
              v
                ? v.charAt(v.length - 1) === "\n"
                  ? v.substring(0, v.length - 1)
                  : v
                : ""
            );
          }}
          onKeyDown={(e: any) => {
            if (e.code === "Enter" && input.length) {
              onSubmit((fn) => {
                setLocked(true);
                fn({ source: "user", text: input });
              });
              // setLastDisplay("");
              setInput("");
            }
          }}
        />
        {/* <Button
        onClick={() => {
          fns.writeToken(undefined, "ollama_chat_session_id");
          window.location.reload();
        }}
        label={"start new conversation"}
        type={""}
        size={"md"}
        span
        animation={false}
        className={`arclight-opacity-100`}
      /> */}
      </div>
    </div>
  );
};

export default InputPrompt;
