import React, { FC } from "react";
import Styles from "./styles";

export interface PropTypes {}

const NoPage: FC<PropTypes> = React.memo(({}) => {
  return (
    <Styles.Container className={`arclight-w-screen arclight-h-screen`}>
      <img src={`https://highmountainlabs.io/cdn/arclight/404.png`} />
    </Styles.Container>
  );
});

export default NoPage;
