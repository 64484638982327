export default (_: { [key: string]: any }) =>
  (obj: { [key: string]: any } | undefined | null) => {
    let __ = `?`;
    if (obj)
      __ += Object.keys(obj)
        .map((k: string) => `${k}=${obj[k]}`)
        .join("&");
    const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.history.pushState(
      { path: newurl },
      "",
      `${newurl}${obj && Object.keys(obj).length ? __ : ""}`
    );
  };
