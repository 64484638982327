import styled from "styled-components";
import { mountHandler } from "../../../components/animations";

const ImgContainer = styled.div`
  ${(props) => mountHandler(props)}
  opacity: 0;
`;

const VideoContainer = styled.video`
  ${(props) => mountHandler(props)}
`;

const ModelsContainer = styled.div`
  ${(props) => mountHandler(props)}
  opacity: 0;
  display: flex-col;
`;

const TextContainer = styled.div`
  ${(props) => mountHandler(props)}
  opacity: 0;
`;

const InputContainer = styled.div`
  ${(props) => mountHandler(props)}
  opacity: 0;
  width: 100%;
  top: 40px;
`;

const AppPanel = styled.div`
  @keyframes load {
    0% {
      opacity: ${(props) => (props.animate ? 0 : 1)};
      max-height: ${(props) => (props.animate ? 0 : "10000px")};
    }
    10% {
      max-height: ${(props) => (props.animate ? 0 : "10000px")};
    }
    15% {
      opacity: ${(props) => (props.animate ? 0 : 1)};
    }
    25% {
      opacity: 1;
    }
    100% {
      max-height: 10000px;
    }
  }
  animation: load 20s ease forwards;
`;

const Cursor = styled.div`
  text-align: right;
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  animation: blink 0.5s linear infinite;
`;

export default {
  TextContainer,
  ImgContainer,
  Cursor,
  AppPanel,
  VideoContainer,
  InputContainer,
  ModelsContainer,
};
