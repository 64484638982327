import React, { FC } from "react";
import { ArticlePage, FontAwesome, HeroPanel, Page } from "../../../components";
import Styles from "./styles";
import init from "../../../projectcomponents/init";
import InputPrompt from "../../../projectcomponents/inputprompt";
import askOllama from "../../../projectcomponents/askollama";

const once = (() => {
  let ran = false;
  return (cb) => {
    if (ran) return;
    ran = true;
    cb();
  };
})();

const Home: FC<{
  fns: {
    [key: string]: any;
  };
  D: { [key: string]: any };
  endpoint?: string;
}> = ({ fns, D, endpoint }) => {
  const animate = true;
  const text = "sanitylabs.io";
  const [conversationname, setconversationname] = React.useState("");
  const [context, setContext] = React.useState([]);
  const [showText, setShowText] = React.useState(animate ? "" : text);
  const [textInput, setTextInput] = React.useState<
    Array<{ source: string; text: string }>
  >([]);
  const askollama = askOllama({ fns, D });
  React.useEffect(() => init(fns, D), [fns]);
  React.useEffect(() => {
    once(() => {
      if (!animate) return;
      const re = async (_t = "") => {
        if (!_t.length) await new Promise((r) => setTimeout(r, 4000));
        if (showText.length < text.length) {
          setShowText(_t + text.charAt(_t.length));
          await new Promise((r) => setTimeout(r, 50));
          return await re(_t + text.charAt(_t.length));
        }
      };
      re();
      setTimeout(async () => {
        const initresponse: any = await askollama({
          prompt: "Introduce yourself.",
          nResults: undefined,
          collection: undefined,
          where: { self: "Chimera" },
          conversationname,
          context,
        });
        setTextInput([
          {
            source: "agent",
            text: initresponse.response,
          },
        ]);
      }, 7000);
    });
  }, []);
  return D && fns.calls && D.getrecords_settings ? (
    <Page
      fns={fns}
      backgroundImage={{
        src: `https://highmountainlabs.io/arclight/static/media/67d32856f97b25d7b11db1a9.jpg`,
        opacity: 0.05,
      }}
    >
      <div
        className={`arclight-flex arclight-flex-col arclight-justify-center arclight-h-screen xl:arclight-overflow-y-hidden`}
      >
        <div
          className={`arclight-absolute arclight-left-0 arclight-right-0 arclight-w-full arclight-h-full arclight-opacity-5`}
        >
          <Styles.VideoContainer
            src={`https://highmountainlabs.io:7001/static/media/65debfeda0ce4f406a2ed722.mp4`}
            muted
            autoPlay
            mountAnim={{
              anim: "fadeOut",
              duration: "8s",
              delay: `3s`,
            }}
            className={`arclight-w-full arclight-h-full arclight-object-cover`}
          />
        </div>
        <Styles.ImgContainer
          mountAnim={{
            anim: "fadeIn",
            duration: "2s",
            delay: animate ? `2s` : `0s`,
          }}
          className={`arclight-mx-auto arclight-text-center arclight-flex arclight-flex-col arclight-space-y-3 arclight-pointer-events-none arclight-w-full lg:arclight-w-3/4 arclight-h-full arclight-justify-center lg:arclight-justify-end arclight-py-10`}
        >
          <Styles.TextContainer
            mountAnim={{
              anim: "fadeIn",
              duration: "1s",
              delay: animate ? `2s` : `0s`,
            }}
            className={`arclight-text-2xl arclight-space-x-3 arclight-flex-col arclight-relative arclight-h-full lg:arclight-h-auto`}
          >
            {/* <FontAwesome icon={"chevron-right"} size={"md"} /> */}
            <div className={`arclight-flex arclight-justify-end`}>
              <div className={`arclight-text-right`}>{showText}</div>
              {showText.length < text.length ? (
                <Styles.Cursor>_</Styles.Cursor>
              ) : null}
            </div>
            <div className={`arclight-flex arclight-space-x-2 arclight-h-full`}>
              <Styles.ModelsContainer
                className={`arclight-space-y-2 arclight-hidden lg:arclight-block`}
                mountAnim={{
                  anim: "fadeIn",
                  duration: "2s",
                  delay: `6s`,
                }}
              >
                <div className={`arclight-text-sm arclight-text-center`}>
                  Models
                </div>
                {["Chimera"].map((M: any) => (
                  <div
                    className={`arclight-w-40 arclight-bg-slate-700 arclight-rounded-sm arclight-text-base`}
                  >
                    {M}
                  </div>
                ))}
              </Styles.ModelsContainer>
              <Styles.InputContainer
                className={`arclight-flex-col arclight-space-y-2`}
                mountAnim={{
                  anim: "fadeIn",
                  duration: "2s",
                  delay: `6s`,
                }}
              >
                <InputPrompt
                  askollama={askollama}
                  fns={fns}
                  text={textInput}
                  onSubmit={(cb) =>
                    cb(async (user) => {
                      const res: any = await askollama({
                        where: undefined,
                        prompt: user.text,
                        nResults: undefined,
                        collection: undefined,
                        conversationname,
                        context,
                      });
                      setconversationname(res.conversationname);
                      setContext(res.context);
                      setTextInput((prev) =>
                        prev.concat(user, {
                          source: "agent",
                          text: res.response,
                        })
                      );
                    })
                  }
                />
                <div
                  className={`arclight-text-xs arclight-text-left arclight-mr-5 ${
                    !conversationname?.length ? `arclight-hidden` : ""
                  }`}
                >
                  Conversation - {conversationname}
                </div>
              </Styles.InputContainer>
            </div>
          </Styles.TextContainer>
        </Styles.ImgContainer>
      </div>
    </Page>
  ) : null;
};

export default Home;
